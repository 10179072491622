<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />

		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="TELEFON GÖRÜŞMESİ" entityName="phonecall"></EntityHeader>

					<div class="grid">
						<div class="col-4">
							<div class="field p-fluid">
								<EntityLookup id="ilgi" v-model="mainData.ilgiName" ref="entity_ilgi"
									label="İlgi" entityName="account" nameField="name" :state="true" :required="true" :disabled="true"
									@itemSelected="ilgiSelected = $event" @itemCleared="ilgiSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="baslangic">Başlangıç Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="baslangic" v-model="mainData.baslangicSaati" autocomplete="off" :showIcon="true" :showTime="true" hourFormat="24"/>
								<span v-if="v$.mainData.baslangicSaati.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.baslangicSaati.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="marka">Marka</label>
								<Dropdown id="marka" v-model="mainData.marka" :options="SM_marka" optionLabel="Value" optionValue="AttributeValue" placeholder="Marka" :showClear="true" :filter="true" />
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="telefonNumarasi">Telefon Numarası</label>
								<InputText id="telefonNumarasi" type="text" v-model="mainData.telefonNumarasi" />
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="directioncode">Yön <span style="color:red">*</span></label>
								<Dropdown id="directioncode" v-model="mainData.yon" :options="SM_directioncode" optionLabel="Value" optionValue="AttributeValue" placeholder="Yön"  :disabled="isDisabled" />
								<span v-if="v$.mainData.yon.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.yon.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>

						<div class="col-12">
							<div class="field p-fluid">
								<label for="konu">Konu <span style="color:red">*</span></label>
								<InputText id="konu" type="text" v-model="mainData.konu" />
								<span v-if="v$.mainData.konu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.konu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						
						<div class="col-12">
							<div class="field p-fluid">
								<label for="aciklama">Açıklama <span style="color:red">*</span></label>
								<Textarea id="aciklama" rows="4" :autoResize="false" v-model="mainData.aciklama" />
								<span v-if="v$.mainData.aciklama.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.aciklama.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>

						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="sahibi" v-model="mainData.sahibiName" ref="entity_sahibi"
									label="Sahibi" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true"
									@itemSelected="sahibiSelected = $event" @itemCleared="sahibiSelected = null"
									parentFieldName="bm_anadepartman" :parentId="{'Value': 1000}">
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookupPopup id="satisprojesi" v-model="mainData.satisProjesiName" ref="entity_satisProjesi" label="Satış Projesi"
									entityName="opportunity" nameField="name" :disabled="isDisabled" :state="true"
									@itemSelected="satisProjesiSelected = $event" @itemCleared="satisProjesiSelected = null"
									parentFieldName="customerid" :parentId="ilgiSelected"
									:CrmDataTable_options="satisProjesi_CrmDataTable_options">
								</EntityLookupPopup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="musteriKatilimci1" v-model="mainData.musteriKatilimci1Name" ref="entity_musteriKatilimci1"
									label="Müşteri Katılımcı" entityName="contact" nameField="fullname" :state="true" :disabled="isDisabled"
									secondNameField="bm_unvan" secondNameFieldType="picklist" parentFieldName="parentcustomerid" :parentId="ilgiSelected"
									@itemSelected="musteriKatilimci1Selected = $event" @itemCleared="musteriKatilimci1Selected = null">
								</EntityLookup>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 4210,
			mainData: {},
			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(); },
				},
				{
					label:'Tamamlandı Olarak Kaydet',
					icon:'pi pi-fw pi-check-circle',
					command: () => { this.OnTamamlandiOlarakKaydet(); },
				},
			],
			satisProjesi_CrmDataTable_options: {
				searchAttributes: ["name", "customeridname"],
				filterParent: [
					{ 'name': 'customerid', 'filterOperator': 'eq', 'value': this.firmaId },
					{ 'name': 'statecode', 'filterOperator': 'eq', 'value': 0 },
				],
            },
		}
	},
	async created() {

		this.crmService = new CrmService();
		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		if (this.firmaId!= null && this.firmaName != null) {
			setTimeout(() => {
				this.ilgiSelected = { "Value": this.firmaId, "Name": this.firmaName };
				this.$refs.entity_ilgi.setDefaultData({ "Value": this.firmaId, "Name": this.firmaName });

				this.sahibiSelected = { "Value": this.profileData.ID, "Name": this.profileData.TamAdi };
				this.$refs.entity_sahibi.setDefaultData({ "Value": this.profileData.ID, "Name": this.profileData.TamAdi });

				this.satisProjesi_CrmDataTable_options.filterParent[0].value = this.firmaId;
			}, 300);
		}

		if (this.opportunityId != null) {
			this.crmService.GetEntityById({
				"entityName": "opportunity",
				"entityId": this.opportunityId,
				"columnNames": ["name", "opportunityid"]})
			.then(data_opportunity => {
				if (data_opportunity.jsonData != null) {
					debugger;

					this.mainData["satisProjesiId"] = data_opportunity.jsonData["opportunityid"];
					this.mainData["satisProjesiName"] = data_opportunity.jsonData["name"];

					this.$refs.entity_satisProjesi.setDefaultData({ "Value": this.mainData["satisProjesiId"], "Name": this.mainData["satisProjesiName"] });
				}
			})
			.catch(error => console.log(error));
		}

		let now = new Date();
		var year = now.getUTCFullYear();
		var month = now.getUTCMonth();
		var day = now.getUTCDate();
		var hour = now.getHours();
		var minute = 0;

		let tarih = new Date(year, month, day, hour, minute);


		this.mainData["baslangicSaati"] = tarih;
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		firmaId() {
			debugger;
			return this.$route.params.id;
		},
		firmaName() {
			debugger;
			return this.$route.params.name;
		},
		opportunityId() {
			debugger;
			return this.$route.params.opportunityId;
		},
		SM_marka: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_ilgilimarka');
		},
		SM_directioncode: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'directioncode', 'bool');
		},
		ilgiSelected: {
			get: function () {
				if (this.mainData["ilgiId"]) {
					return { "Value": this.mainData["ilgiId"], "Name": this.mainData["ilgiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["ilgiId"] = null;
					this.mainData["ilgiName"] = null;
				}
				else {
					this.mainData["ilgiId"] = newValue.Value;
					this.mainData["ilgiName"] = newValue.Name;

					
					this.crmService.GetEntityById({
						"entityName": "account",
						"entityId": newValue.Value,
						"columnNames": ["telephone1"]})
					.then(data => {
						if (data.jsonData != null) {
							this.mainData["telefonNumarasi"] = data.jsonData.telephone1;
							debugger;
						}
					});
				}
			}
		},
		sahibiSelected: {
			get: function () {
				if (this.mainData["sahibiId"]) {
					return { "Value": this.mainData["sahibiId"], "Name": this.mainData["sahibiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["sahibiId"] = null;
					this.mainData["sahibiName"] = null;
				}
				else {
					this.mainData["sahibiId"] = newValue.Value;
					this.mainData["sahibiName"] = newValue.Name;
				}
			}
		},
		satisProjesiSelected: {
			get: function () {
				if (this.mainData["satisProjesiId"]) {
					return { "Value": this.mainData["satisProjesiId"], "Name": this.mainData["satisProjesiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["satisProjesiId"] = null;
					this.mainData["satisProjesiName"] = null;
				}
				else {
					this.mainData["satisProjesiId"] = newValue.Value;
					this.mainData["satisProjesiName"] = newValue.Name;
				}
			}
		},
		musteriKatilimci1Selected: {
			get: function () {
				if (this.mainData["musteriKatilimci1Id"]) {
					return { "Value": this.mainData["musteriKatilimci1Id"], "Name": this.mainData["musteriKatilimci1Name"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["musteriKatilimci1Id"] = null;
					this.mainData["musteriKatilimci1Name"] = null;
				}
				else {
					this.mainData["musteriKatilimci1Id"] = newValue.Value;
					this.mainData["musteriKatilimci1Name"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnSave() {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getTelefonGorusmesiCreate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});

							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							setTimeout(() => {
								this.$router.push({ name: 'phonecall', params: { id: response.olusturulanId } });
							}, 2000);
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnTamamlandiOlarakKaydet(){
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response_save = await this.crmService.getTelefonGorusmesiCreate(this.mainData);
					if (response_save) {
						if (response_save.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response_save.hataAciklamasi, life: 5000});
						}else {
							const response_tamamla = await this.crmService.getTelefonGorusmesiTamamlandiOlarakIsaretle(response_save.olusturulanId);
							debugger;
							if (response_tamamla) {
								if (response_tamamla.hata == true) {
									this.$toast.add({severity:'error', summary: 'Telefon Görüşmesi Kapatılamadı', detail: response_tamamla.hataAciklamasi, life: 5000});
								}else {
									this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Telefon Görüşmesi Başarıyla tamamlandı', life: 3000});

									if (window.opener) {
										window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
									}
									
									setTimeout(() => {
										window.close();
									}, 500);
								}
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	validations() {
		return {
			mainData: {
				ilgiName: {
					required : helpers.withMessage('İlgi alanı dolu olmalıdır!', required),
				},
				konu: {
					required : helpers.withMessage('Konu alanı dolu olmalıdır!', required),
				},
				aciklama: {
					required : helpers.withMessage('Açıklama alanı dolu olmalıdır!', required),
				},
				baslangicSaati: {
					required : helpers.withMessage('Başlangıç Tarihi alanı dolu olmalıdır!', required),
				},
				yon: {
					required : helpers.withMessage('Yön alanı dolu olmalıdır!', required),
				}
			},
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
